import React, {
  useCallback
} from 'react';

import {
  CircularProgress,
  Grid,
  Typography as T
} from '@material-ui/core';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  Overlay,
  ProfileForm
} from 'components';

import {
  useBodyScroll,
  useQuery
} from 'hooks';

import {
  fetchCollection
} from 'services/firebase';

// ...

const useStyles = makeStyles(theme => {
  return {
    outerContainer: {
      padding: theme.spacing(1)
    }
  };
})

// ...

export function Signup({ credentials, actions }) {
  useBodyScroll();

  const styles = useStyles();

  const formValues = useQuery(
    useCallback(() => {
      async function fetchCollections() {
        const [
          titles,
          departments,
          roles
        ] = await Promise.all([
          fetchCollection('titles'),
          fetchCollection('departments'),
          fetchCollection('roles')
        ]);

        return {
          titles,
          departments,
          roles
        };
      }

      return fetchCollections();
    }, [])
  );

  // ...

  if (!formValues) {
    return (
      <Overlay
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </Overlay>
    )
  }

  // Set body overflow style to visible to allow scrolling. 
  // UseBodyScroll doesn't work due to ModalManager.js (invoked by Toolbar) 
  // setting this attribute to hidden after first call to this function
  document.body.style.overflowY = 'visible';
  document.body.style.overflowX = 'hidden';


  return (
    <Grid
      container
      className={styles.outerContainer}      
    >
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}

        item

        container
        justify="center"
      >
        <T
          style={{
            paddingTop: 25,
            paddingBottom: 25
          }}
          variant="h5">
          Signup for Vision Beyond
        </T>
      </Grid>
      <Grid item>
        <ProfileForm
          data={{
            credentials,
            formValues
          }}
          actions={actions}
          type='create'
        />
      </Grid>
    </Grid>
  )
}