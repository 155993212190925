import React from 'react';

import { 
  Navigate, 
  Route, 
  
  useLocation 
} from 'react-router-dom';

import { 
  useAuth
} from "../hooks";

// ...

export const RouteGuard = ({ path, element, ...rest }) => {
  const {
    user,
    mfaConfirmed
  } = useAuth();

  const location = useLocation();

  // ...
  
  return user && mfaConfirmed === true
    ? <Route path={path} element={element} {...rest} />
    : <Navigate to="/auth" state={{ from: location }} />;
};
