import React, {
  useState,
  useEffect,
  useRef
} from 'react';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  baseStyles
} from './baseStyles';

import {
  ReactComponent as DiscardIcon
} from 'assets/annotation_tools.svg';

import {
  getAnnotationStrategy
} from './annotationStrategy';

// ...

const useStyles = makeStyles({
  ...baseStyles,

  controls: {
    height: 36,
    width: 125,

    position: 'fixed',
    bottom: 24,
    left: 24,
    zIndex: 4,

    display: 'flex',
    justifyContent: 'space-between'
  },

  button: {
    height: '100%',
    width: 'auto',
    cursor: 'pointer'
  }
});

// ...

function ScreenControls({ styles, onDiscard, onConfirm }) {
  return (
    <div className={styles.controls}>
      <DiscardIcon className={styles.button} onClick={onDiscard} />
      {/* <ConfirmIcon className={styles.button} onClick={onConfirm} /> */}
    </div>
  )
}

// ...

export function LiveAnnotation({ context, type, actions, color }) {
  const styles = useStyles();
  const [strategy, setStrategy] = useState(null);
  const canvasRef = useRef();

  const onHotspot = (annotationData) => {
    if (!annotationData) {
      // TBD: Notify user that an annotation must be drawn before sending it.
      return;
    }
    actions.onAnnotation(type, annotationData);
  }

  const onHotspotEnd = (annotationData) => {
    actions.onEndAnnotation(annotationData);
  }

  useEffect(() => {
    var strat = getAnnotationStrategy(type, canvasRef.current, color, context.isFullscreen, onHotspot, onHotspotEnd)
    setStrategy(strat);
  }, [type, color, context.isFullscreen]);

  // ...

  return (
    <div className={styles.container}>
      <ScreenControls

        styles={{
          controls: styles.controls,
          button: styles.button
        }}

        onDiscard={actions.onDiscard}

        onConfirm={() => {
          const annotationData = strategy.getAnnotationData();
          if (!annotationData) {
            // TBD: Notify user that an annotation must be drawn before sending it.
            return;
          }

          actions.onAnnotation(type, annotationData);
        }}
      />

      <canvas
        id="liveAnnotationCanvas"
        ref={canvasRef}
        className={styles.canvas}

        style={{
          position: 'absolute',
          left: context.videoAbsRect.x,
          top: context.videoAbsRect.y,
          width: context.videoAbsRect.width,
          height: context.videoAbsRect.height
        }}

        {...strategy?.getEventHandlers()}
      >
      </canvas>
    </div>
  )
}