const actions = {
  onAccept() {
    return {
      type: 'JOIN_ACCEPT'
    };
  },
  onRefuse() {
    return {
      type: 'JOIN_REFUSE'
    };
  },
  onLeave() {
    return {
      type: 'LEAVE'
    };
  }
};

const bindAction = (action, send) => {
  return () => send(action());
};

// ...

export const getActions = send => {
  const boundActions = {};

  for (const key in actions) {
    boundActions[key] = bindAction(actions[key], send);
  }

  return boundActions;
};