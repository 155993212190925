import {
  Hotspot as HOTSPOT,
} from './hotspot';

import {
  Freeform as FREEFORM
} from './freeform';

import {
  Arrow as ARROW
} from './arrow';

// ...

const strategies = {
  HOTSPOT,
  FREEFORM,
  ARROW
};

// ...

export function getAnnotationStrategy(type, canvas, color, isFullscreen, startCallback, endCallback) {
  return new strategies[type](canvas, color, isFullscreen, startCallback, endCallback);
}