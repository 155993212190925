import React, {
  useEffect,
  useState
} from 'react';

import {
  useNavigate
} from 'react-router-dom';

import {
  Button,
  Grid,
  TextField,
  Typography as T
} from '@material-ui/core';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  Formik,
  Form,
  Field,
  ErrorMessage
} from 'formik';

import * as Yup from 'yup';

import {
  useAuth,
  useError
} from 'hooks';

import {
  baseStyles
} from '../../baseStyles';

import {
  sendSMS
} from 'services/firebase'

import firebase from 'firebase';
// ...

const useStyles = makeStyles(theme => ({
  button: {
    ...baseStyles.button,

    backgroundColor: theme.button.primary
  },

  container: {
    paddingLeft: '15%',
    paddingRight: '15%',
    marginTop: '10%',
    marginBottom: '10%'
  },

  header: {
    textAlign: 'center',
    paddingBottom: 25
  },

  field: {
    paddingBottom: 15
  }
}));

// ...
// Form settings.
const initialValues = {
  username: '',
  password: ''
};

const validationSchema = Yup.object().shape({
  username:
    Yup
      .string()
      .email('Invalid email address')
      .required('Required')
      .nullable(),
  password:
    Yup
      .string()
      .required('Required')
      .min(6, 'Must be at least 6 characters')
});

// ...

const errors = {
  'auth/user-not-found': 'The user with the provided email address does not exist',
  'auth/wrong-password': 'The password is invalid',
  'auth/multi-factor-auth-required': 'Multi factor authorization is required.',
  'auth/captcha-check-failed': 'Captcha could not validate you ',
  'auth/missing-phone-number': 'Phone number for this user is missing.',
  'auth/user-disabled': 'User is disabled.',
  'auth/unverified-email': 'User has unverified e-mail.',
  'auth/invalid-email': 'The email address is invalid'
};

const SIGNIN_BUTTON_ID = "signinbutton";

// ...

export function CredentialsForm({ actions }) {
  const styles = useStyles();

  const navigate = useNavigate();

  const {
    refreshProfile,
    profile,
    signIn,
    initRecaptcha,
    sendEmailVerification,
    signOut
  } = useAuth();

  const {
    notify
  } = useError();

  // ...

  const [recaptchaVerifier, setUpRecaptcha] = useState();
  const [readyToSend, setReadyToSend] = useState(false);

  useEffect(() => {
    try{
      const recaptcha = initRecaptcha(SIGNIN_BUTTON_ID, {
        'size': 'invisible',
        'badge': 'bottomleft',
      });
      setUpRecaptcha(recaptcha)
    } catch (error){
      notify(errors[error.code] ?? 'Unknown authentication error');
    }
}, []);

  useEffect(() => {
    const sendCode = async () => { 
      if(profile){
        try{
          if(readyToSend){
            setReadyToSend(false);
            var phoneNum = profile.phoneNumber;
            var docId = profile.id;
            sendSMS(docId);  // calls Cloud Function to send SMS code via Twilio
            handlePhoneFactor(phoneNum);  // opens PhoneFactorForm.jsx
          }
        } catch (error) {
          notify(errors[error.code] ?? 'Unknown authentication error');
        };
      };
    }
    sendCode();
  }, [profile]);
  
  const handleSubmit = async ({ username: email, password }) => {
    recaptchaVerifier.render();
    await recaptchaVerifier.verify();
    try {
      // sign the user in with no persistence so they must enter a MFA code to be truly logged in
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
      // must signIn to get the users phone number
      var userCred = await signIn(email, password);

      if(!userCred.user.emailVerified){
        notify("E-mail is not verified, re-sending verification e-mail.");
        sendEmailVerification(userCred.user);
        signOut(false);
        navigate('/auth');
      } else {
        setReadyToSend(true);
        await refreshProfile(userCred.user.uid);
      };
    } catch (error) {
        navigate('/');
        notify(errors[error.code] ?? 'Unknown authentication error');
      }
  };

  const {
    handleRegister,
    handlePasswordReset,
    handlePhoneFactor
  } = actions;

  // ...

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}

      validateOnChange
    >
      {({ values, dirty, isValid, isSubmitting }) => (
        <Grid
          className={styles.container}
          container
          direction="column"
        >
          <Grid item>
            <T className={styles.header} variant="h5">
              Welcome to Vision Beyond
            </T>
          </Grid>
          <Grid item>
            <Form
              noValidate
              autoComplete="off"
            >
              <Field
                name="username"
                placeholder="Email"

                as={TextField}
                className={styles.field}
                variant="outlined"
                fullWidth
                helperText={<ErrorMessage name="username" />}
              />
              <Field
                name="password"
                type="password"
                placeholder="Password"

                as={TextField}
                className={styles.field}
                variant="outlined"
                fullWidth
                helperText={<ErrorMessage name="password" />}
              />

              <Grid container justify="space-between">
                <Button
                  disabled={isSubmitting}
                  disableElevation
                  onClick={() => {
                    handleRegister(values)
                  }}
                >
                  REGISTER
                </Button>
                <Button
                  className={styles.button}
                  id={SIGNIN_BUTTON_ID}
                  type="submit"
                  disabled={!(dirty && isValid) || isSubmitting}
                  disableElevation
                >
                  SIGN IN
                </Button>
              </Grid>

              <Grid container justify="flex-start">
                <Button
                  disabled={isSubmitting}
                  disableElevation
                  onClick={() => {
                    handlePasswordReset(values)
                  }}
                >
                  RESET PASSWORD
                </Button>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}