import React, {
  useState
} from 'react';

import {
  useNavigate
} from 'react-router-dom';

import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography as T
} from '@material-ui/core';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  useFormik
} from 'formik';

import MuiPhoneNumber from 'material-ui-phone-number';

import {
  useAuth,
  useError,
  useUpload
} from 'hooks';

import * as Yup from 'yup';

import {
  Overlay, 
  PinCode
} from 'components';

import {
  updateProfile, 
  createProfile
} from 'services/firebase';

import {
  ProfileImage
} from './ProfileImage';

import {
  Select
} from './Select';

// ...

const emptyProfile = {
  first:
    '',
  last:
    '',
  phoneNumber:
    '',
  titleRef:
    '',
  departmentRef:
    '',
  primaryRoleRef:
    '',
  secondaryRoleRef:
    '',
  description:
    '',
  pin:
    '',
  image:
    null
};

// ...

const validationSchema = Yup.object().shape({
  first:
    Yup
      .string()
      .required(),
  last:
      Yup
        .string()
        .required(),
  phoneNumber:
    Yup
      .string()
      .required()
      .matches(/^\+[1-9]\d{1,14}$/), // E.164 matching as required by Twilio
  titleRef:
    Yup
      .string()
      .required(),
  primaryRoleRef:
    Yup
      .string()
      .required(),
  pin:
    Yup
      .string()
      .required()
      .matches(/^[0-9]{6}$/)
});

// ...

const useStyles = makeStyles(theme => {

  const button = {
    padding: '10px 20px',
    color: 'white',
    borderRadius: 20
  };

  return {
    avatar: {
      width: 180,
      height: 180
    },

    inputGridItem: {
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1)
      },
    },

    outerContainer: {
      padding: theme.spacing(6),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1)
      },
    },
    
    uploadButton: {
      ...button,

      backgroundColor: 'black',
      marginTop: 40,
      marginBottom: 40
    },

    buttonCancel: {
      ...button,

      backgroundColor: theme.button.secondary,
      marginRight: 10
    },

    buttonSubmit:{
      ...button,

      backgroundColor: theme.button.primary
    },

    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 999,

      backgroundColor: 'rgba(0,0,0,0.5)',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
})

// ...

function getSelectOptions(collection) {
  return collection.map(({ id, name }) => (
    <MenuItem
      key={id}
      value={id}
    >
      {name}
    </MenuItem>
  ));
}

// ...

export function ProfileForm({ data, actions, type}) {
  const styles = useStyles();

  // ...

  const [
    submitting,
    setSubmitting,
  ] = useState(false);

  // ...

  const {
    signup,
    refreshProfile,
    signOut    
  } = useAuth();

  const {
    notify
  } = useError();

  // ...

  const {
    user,
    profile
  } = data;

  const initialValues = {
    ...emptyProfile,
    ...profile
  };

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema,

    async onSubmit(values) {
      if (type === 'edit' ){
          try {        
            if(values.phoneNumber !== profile.phoneNumber){
              
              notify("Phone number change detected. Your verification codes will be sent to the new number.")
            }
            setSubmitting(true);
    
            const updatedProfile = {
              ...profile,
              ...values
            };
    
            await updateProfile(user.uid, updatedProfile);
            await refreshProfile(user.uid);
    
            // ...
    
            setSubmitting(false);
    
            return navigate('/');
          } catch (error) {
            setSubmitting(false);
            console.log(error);
          }  
      } else {
        const {
          username,
          password,
          AR
        } = data.credentials;
  
        try {
          setSubmitting(true);
  
          // ...
          
          const uid = await signup(username, password);
  
          await createProfile(uid, {
            ...values,
            AR
          });
  
          // ...
          await refreshProfile(uid);
  
          setSubmitting(false);
  
          notify("A verification link has been sent to your e-mail. Please verify before logging in.");

          // Await signout to ensure navigation to root ends-up on sign-in screen.
          await signOut(false);

          return navigate('/');
        } catch (error) {
          setSubmitting(false);
          console.log(error);
        }
      }
    },     
  });

  // ...

  const cleanPhoneNumber
    = number => number.replaceAll(/[()^\s-]/gi, "");

  const handleCancel = type === 'edit' ? () => navigate('/') : actions.handleCancel

  // ...

  const [
    upload,
    handleUpload
  ] = useUpload();

  const open = !!upload;

  const handleClose
    = () => handleUpload(null);

  const handleSelect
    = profileImage => {
      handleUpload(null);

      formik.setFieldValue('image', profileImage, false);

      formik.validateForm();
    };

  // ...

  const {
    formValues
  } = data;

  // ...

  return (
    <>
      {submitting
        ? (
          <Overlay
            style={{
              backgroundColor: 'rgba(0,0,0,0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </Overlay>
        )
        : null}    
      {open
        ? (
          <ProfileImage
            image={upload}
            onClose={handleClose}
            onSelect={handleSelect}
          />
        )
        : null}
      <form
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Grid container
          className={styles.outerContainer}
        >
          <Grid
            item
            xs={12}
            sm={5}

            container
            direction="column"
            alignItems="center"
          >
            <Avatar
              className={styles.avatar}
              src={formik.values.image}
            />
            <Button
              className={styles.uploadButton}
              component="label"
            >
              Upload Photo
              <input
                type="file"
                accept="image/jpeg, image/png"
                style={{ display: "none" }}
                onChange={e => {
                  e.preventDefault();

                  if (e.target.files.length > 0) {
                    const file = e.target.files[0];

                    handleUpload(file);
                  }
                }}
              />
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
          >
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                xs={12}

                container
              >
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={styles.inputGridItem}
                >
                  <TextField
                    name="first"
                    {...formik.getFieldProps('first')}
                    label="First name"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item sm={1} />
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={styles.inputGridItem}
                >
                  <TextField
                    name="last"
                    {...formik.getFieldProps('last')}

                    label="Last name"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}

                container
              >
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={styles.inputGridItem}
                >
                  <MuiPhoneNumber
                    name="phoneNumber"
                    label="Phone number"
                    {...formik.getFieldProps('phoneNumber')}
                    onChange={e => formik.setFieldValue("phoneNumber", cleanPhoneNumber(e)) }
                    defaultCountry={'us'}
                    variant="outlined"
                    fullWidth
                    required
                    />
                </Grid>
                <Grid item sm={7} />
              </Grid>
              <Grid
                item
                xs={12}

                container
              >
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={styles.inputGridItem}
                >
                  <Select
                    name="titleRef"
                    {...formik.getFieldProps('titleRef')}
                    label="Title"
                  >
                    {getSelectOptions(formValues.titles)}
                  </Select>
                </Grid>
                <Grid item sm={1} />
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={styles.inputGridItem}
                >
                  <TextField
                    name="departmentRef"
                    {...formik.getFieldProps('departmentRef')}

                    select
                    label="Department"
                    variant="outlined"
                    fullWidth
                  >
                    {[
                      <MenuItem key="none" value="">
                        <em>None</em>
                      </MenuItem>,
                      ...getSelectOptions(formValues.departments)
                    ]}
                  </TextField>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}

                container
              >
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={styles.inputGridItem}
                >
                  <Select
                    name="primaryRoleRef"
                    {...formik.getFieldProps('primaryRoleRef')}
                    label="Primary role"
                  >
                    {getSelectOptions(formValues.roles)}
                  </Select>
                </Grid>
                <Grid item sm={1} />
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={styles.inputGridItem}
                >
                  <TextField
                    name="secondaryRoleRef"
                    {...formik.getFieldProps('secondaryRoleRef')}

                    select
                    label="Secondary role"
                    variant="outlined"
                    fullWidth
                  >
                    {[
                      <MenuItem key="none" value="">
                        <em>None</em>
                      </MenuItem>,
                      ...getSelectOptions(formValues.roles)
                    ]}
                  </TextField>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={11}
                className={styles.inputGridItem}
              >
                <TextField
                  name="description"
                  {...formik.getFieldProps('description')}

                  placeholder="Short description of your professional profile"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 140 }}
                  label="Description"
                  helperText="Max 140 characters"
                />
              </Grid>
              <Grid
                item
                xs={12}

                container
              >
                <Grid
                  item 
                  xs={12}
                  sm={11}

                  align="right"
                >
                  <T
                    style={{
                      marginRight: 10
                    }}
                    variant="caption"
                  >
                    PIN<sup>*</sup>
                  </T>
                  <PinCode setPin={(code, shouldValidate) => formik.setFieldValue('pin', code, shouldValidate)} />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={11}

                container
                justify="flex-end"
              >
                <Button
                  className={styles.buttonCancel}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className={styles.buttonSubmit}
                  type="submit"
                  disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}
                >
                  {type ==='edit' ? 'Save' : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  )
}