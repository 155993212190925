import React, {
  useEffect,
}  from 'react';

import {
  useLocation,
  useNavigate
} from 'react-router-dom';

import {
  Button,
  Grid,
  Typography as T
} from '@material-ui/core';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  useAuth,
  useError
} from 'hooks';

import {
  baseStyles
} from '../../baseStyles';

// ...

const useStyles = makeStyles(theme => ({
  button: {
    ...baseStyles.button,

    backgroundColor: theme.button.primary
  },

  buttonCancel: {
    ...baseStyles.button,

    backgroundColor: theme.button.secondary,
    marginRight: 10
  },

  container: {
    paddingLeft: '15%',
    paddingRight: '15%',
    marginTop: '10%',
    marginBottom: '10%'
  },

  header: {
    textAlign: 'center',
    paddingBottom: 25
  },

  field: {
    paddingBottom: 15
  }
}));

const errors = {
  'auth/invalid-action-code': 'The code you used is invalid. This may be because it has already been used. You can try again, or re-attempt log-in to get a new code.',
  'auth/expired-action-code': 'The code you used has expired. Another code will be sent if you log in.'
};

export function VerifyEmailPage({ actions }) {
  const styles = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const code = location.search
    .split("&")[1]
    .split("=")[1];


  useEffect(() => {
    try{
      verifyEmail(code);
    } catch (error){
      notify(errors[error.code] ?? 'Unknown authentication error');
    }
  }, []);

  const handleOk = () => {
    const to = location.state?.from ?? '/';
    navigate(to);
  }
  
  const {
    verifyEmail
  } = useAuth();

  const {
    notify
  } = useError();

  // ...

  return (
    <Grid
          className={styles.container}
          container
          direction="column"
          
        >
          <Grid item>
            <T className={styles.header} variant="h5">
              Email Validated
            </T>
          </Grid>
          <Grid container justify="center">
            <Button className = {styles.button} onClick={handleOk} >
                Ok
            </Button>
          </Grid>
        </Grid>
  )
}