export const firebaseConfig = {
  apiKey: "AIzaSyDO8NkBmStkBONZAvGf-fc3spTqtct9coQ",
  authDomain: "simpsonhouse.firebaseapp.com",
  projectId: "simpsonhouse",
  storageBucket: "simpsonhouse.appspot.com",
  messagingSenderId: "86860225502",
  appId: "1:86860225502:web:129bd51701f8b9af375b4f"
};

export const vapidKey = `BJ8duVOQ_9Hse7gZ8Jp2PhVakxFP1ElueR7Ah6ibUfOK5jelnAbWwENEw-_8sYZrLgY0SI19_heeU9mOGuRHD_A`;

// ...

const region = 'us-central1';

export function getCloudFunctionUrl(cfName) {
  if (process.env.REACT_APP_TOKEN_EMULATOR === 'true') {
    return `http://localhost:5001/${firebaseConfig.projectId}/${region}/${cfName}`;
  } 

  return `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net/${cfName}`;
}