import AgoraRtm from 'agora-rtm-sdk';

import {
  getAgoraService
} from 'services/agora';

import {
  getToken
} from 'services/agora/token';

import {
  fetchProfile
} from 'services/firebase';

// ...

// ...
// Agora service (callback) invoked by agoraMachine.
export const agoraService = (_context, _event) => (callback, onEvent) => {
  const {
    rtm,
    rtc
  } = getAgoraService();

  // ...
  // RTM events.
  rtm.on('ConnectionStateChanged', (newState, reason) => {
    const {
      ConnectionState,
      ConnectionChangeReason
    } = AgoraRtm;

    if (newState === ConnectionState.CONNECTING && reason === ConnectionChangeReason.LOGIN) {
      return callback('LOGIN_REQUEST');
    }

    if (newState === ConnectionState.CONNECTED && reason === ConnectionChangeReason.LOGIN_SUCCESS) {
      return callback('LOGIN_SUCCESS');
    }
  });

  rtm.on('MessageFromPeer', ({ text }, peerId) => {
    // message = { type, data }.
    const message = {
      ...JSON.parse(text),

      peerId
    };

    console.log(message);
    callback(message);
  });

  // ...
  // RTC events.

  // ...
  // State machine events.
  onEvent(async (event) => {
    switch (event.type) {
      case 'AGORA_REJECT':
        rtm.sendMessage(
          {
            type: 'REJECT_CALL'
          },
          event.channel.host
        );
      break;

      case 'AGORA_JOIN':
        // ...
        // RTM.
        const channel = rtm.getChannel(event.channel.id);

        channel.on('ChannelMessage', ({ text }) => {
          console.log(text);
          callback(JSON.parse(text));
        });

        channel.on('MemberJoined', async (memberId) => {
          const profile = await fetchProfile(memberId);

          callback({
            type: 'MEMBER_JOINED',

            profile
          });
        });

        channel.on('MemberLeft', memberId => {
          callback({
            type: 'MEMBER_LEFT',

            uid: memberId
          })

          // ...
          // If the channel host leaves, the call has ended.
          if (event.channel.host === memberId) {
            callback('LEAVE');
          }
        });

        await rtm.join();

        // upon joining RTM channel, get snapshot of channel members and update the agora state machine.
        const uids
          = await channel.getMembers();

        const fetchPromises
          = uids
            .filter(uid => uid !== rtm.getUser())
            .map(uid => fetchProfile(uid));

        const profiles
          = await Promise.all(fetchPromises);
        
        profiles.forEach(profile => callback({
          type: 'MEMBER_JOINED',

          profile
        }));

        // ...
        // RTC.
        const rtcToken = await getToken({
          type: 'rtc',
          channelName: event.channel.id
        });

        await rtc.join(
          event.channel.id,
          rtcToken,
          {
            'connection-state-change': (curState, _revState, _reason) => {
              if (curState === 'RECONNECTING') {
                // ...
              }

              if (curState === 'DISCONNECTED') {
                // ...
              }
            }
          }
        );
        
        rtm.sendMessage(
          {
            type: 'CALL_JOINED',
            data: {
              rtcUid: getAgoraService().rtc.getUid()
            }
          },
          event.channel.host
        );

        callback('JOIN_SUCCESS');

        break;

      // ...

      case 'AGORA_LEAVE':
        rtc.leave();
        rtm.leave();

        break;

      // ...
      // Proxy events.
      case 'PROXY_EVENT':
        const proxyEvent = event.event;

        console.log(proxyEvent);

        rtm.sendMessage(
          proxyEvent,
          event.channel.host
        );

        break;

      // ...

      default:
        throw new Error(`Cannot handle state machine event ${event.type}.`);
    }
  });
};