import {
  normalizePoint,
  offsetFromMouseEvent,
  offsetFromTouchEvent
} from './util';

// ...

const MAX_POSITIONS = 10000;


// ...

export class Freeform {
  constructor(canvas, color) {
    this.canvas = canvas;

    this.color = color
      ? `
      rgb(${Math.round(color.r * 255)}, ${Math.round(color.g * 255)}, ${Math.round(color.b * 255)})
    `
      : '#EECB4E';

    // drawing state.
    this.segments = null;

    this.segment = null;
    this.position = null;

    this.noPositions = 0;
  }

  drawStart = ({ offsetX, offsetY }) => {
    this.drawing = true;

    if (!this.segments) {
      this.segments = [];
    }

    this.position = {
      x: offsetX,
      y: offsetY
    };
  };

  drawEnd = () => {
    this.drawing = false;

    if (this.segment) {
      this.segments.push({
        Positions: this.segment
      });

      this.segment = null;
      this.position = null;
    }
  };

  drawMove = ({ offsetX, offsetY }) => {
    if (this.drawing) {
      if (this.noPositions > MAX_POSITIONS) {
        return;
      }

      const {
        x,
        y
      } = this.position;

      if (Math.abs(offsetX - x) <= 1 && Math.abs(offsetY - y) <= 1) {
        return;
      }

      // begin segment.
      !this.segment && (this.segment = []);

      this.paint(x, y, offsetX, offsetY);

      if (this.segment.length === 0) {
        this.segment.push(normalizePoint(x, y, this.canvas.width, this.canvas.height));
      }

      this.segment.push(normalizePoint(offsetX, offsetY, this.canvas.width, this.canvas.height));

      this.noPositions++;

      // update current position.
      this.position = {
        x: offsetX,
        y: offsetY
      };
    }
  };

  // ...

  onMouseDown = ({ nativeEvent }) => {
    this.drawStart(offsetFromMouseEvent(nativeEvent));
  };

  onTouchStart = event => {
    event.preventDefault();

    // ...

    this.drawStart(offsetFromTouchEvent(event.nativeEvent));
  };

  onMouseUp = () => {
    this.drawEnd();
  };

  onTouchEnd = event => {
    event.preventDefault();

    // ...

    this.drawEnd();
  };

  onMouseMove = ({ nativeEvent }) => {
    this.drawMove(offsetFromMouseEvent(nativeEvent));
  };

  onTouchMove = event => {
    event.preventDefault();

    // ...

    this.drawMove(offsetFromTouchEvent(event.nativeEvent));
  };

  paint(sx, sy, dx, dy) {
    const ctx = this.canvas.getContext('2d');

    ctx.beginPath();
    ctx.strokeStyle = this.color;
    ctx.lineWidth = 5;

    ctx.moveTo(sx, sy);
    ctx.lineTo(dx, dy);

    ctx.stroke();
  }

  // ...

  getEventHandlers() {
    return {
      onTouchStart:
        this.onTouchStart,
      onTouchEnd:
        this.onTouchEnd,
      onTouchMove:
        this.onTouchMove,
      onMouseDown:
        this.onMouseDown,
      onMouseUp:
        this.onMouseUp,
      onMouseMove:
        this.onMouseMove
    };
  }

  getAnnotationData() {
    return {
      Segments: this.segments
    };
  }
}