import React, {
  useCallback,
  useEffect,
  useState
} from 'react';

import {
  useService
} from '@xstate/react';

import {
  makeStyles
} from '@material-ui/core/styles';

import clsx from 'clsx';

import {
  useValue,
  useQuery,
  useAuth
} from 'hooks';

import * as firebase from 'services/firebase';

import {
  baseStyles
} from '../../components/baseStyles';

import {
  Toolbar
} from '../../components/Toolbar';

import {
  useAudio
} from './useAudio';

import {
  useVideo
} from './useVideo';

import {
  Controls
} from './Controls';

import {
  LiveAnnotation
} from './views';

// ...

const {
  fullscreen,
} = baseStyles;

const useStyles = makeStyles({
  background: {

    zIndex: 1,
    backgroundColor: 'rgb(210, 210, 210)',
    width: "100%",
    height: "100%"
  },

  overlay: {
    zIndex: 2,

    display: 'flex',
    flexDirection: 'column'
  },

  fullscreen: {
    ...fullscreen
  },

  hide: {
    visibility: 'hidden'
  },

  center: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },

  text: {
    visibility: 'visible',
    fontSize: 24,
    color: 'white',
    textAlign: 'center'
  },

});

// ...

function getAnnotationElement(state, send) {
  switch (true) {
    case state.matches('default'):
      return (
        <Controls.AnnotationButton
          mode={state.context.videoSubscribed ? 'enabled' : 'disabled'}
          onFrameRequest={() => send('LIVE_ANNOTATION_START')}
        />
      )

    case state.matches('capturing'):
      return (
        <Controls.AnnotationButton
          mode="disabled"
          onFrameRequest={() => send('FRAME_REQUEST')}
        />
      )

    case state.matches('captured'):
      return (
        <Controls.AnnotationSelect
          onSelect={annotationType => send({
            type: 'SELECT',

            annotationType
          })}
          onCancel={() => send('CANCEL')}
        />
      )

    default:
      return null;
  }
}

// ...

export function Call({ context, actions, service }) {
  const [state, send] = useService(service);
  const [isFullscreen, setFullscreen] = useState(true); // should be fullscreen by default
  const [videoAbsRect, setVideoAbsRect] = useState({ x: 0, y: 0, width: 1280, height: 720 });

  const styles = useStyles();

  const {
    user
  } = useAuth();
  // ...

  const {
    mute,
    setMute
  } = useAudio();

  let mode;

  if (state.matches('default') || state.matches('live_annotating')) {
    mode = 'video-play';
  } else if (state.matches('capturing') && state.context.frameData) {
    mode = 'video-stop';
  }

  mode = useValue(mode);

  useVideo(
    mode,
    () => {
      send({
        type: 'VIDEO_SUBSCRIBED',

        value: true
      });
    },
    frameGrab => {
      send({
        type: 'FRAME_GRAB',

        frameGrab
      });
    }
  );

  // ...
  
  useEffect(() => {

    /**
     * Fits the video to the full browser window and centers it over the screen. The video
     * will scale appropriately so that it is always using as much space as possible, however
     * the viewport aspect ratio may change. If it does, the video will be stretched to fill
     * the shorter dimension first, and then adapt and center over the longer dimension.
     */
    function fitVideo_fullscreen() {

      // This element should fit in the fullscreen boundaries.
      let elBg = document.getElementById('video-background');
      if (!elBg)
      {
        return;
      }
      let rect = elBg.getBoundingClientRect()

      // Aspect ratios
      let videoRatio = 16 / 9
      
      // Aspect ratio of the video container.
      let windowWidth = rect.width
      let windowHeight = rect.height
      let windowRatio = windowWidth / windowHeight

      // Calculate these based on the size of the window. These values will be applied as the
      // new boundaries of the windowed video.
      let videoWidth = 0
      let videoHeight = 0
      let videoTop = 0
      let videoLeft = 0

      // Determine how to position the fullscreen video.
      if (windowRatio > videoRatio) {   

        // Video will show whitespace if use full height, so constrain to the width of the frame 
        // and allow the height of the video to centered and clipped instead.
        videoWidth = rect.width
        videoHeight = (videoWidth * 9) / 16
        videoTop = (rect.height - videoHeight) / 2

      } 
      else {

        // Video will show whitespace if use full width, so constrain to the height of the frame 
        // and allow the width of the video to centered and clipped instead.
        videoHeight = rect.height
        videoWidth = (videoHeight * 16) / 9

      }

      // Calculate offsets to center container to view
      videoLeft = (windowWidth - videoWidth) / 2
      videoTop = (windowHeight - videoHeight) / 2

      // Apply to the fullscreen video container.
      let el = document.getElementById('video-container');
      if (el) {

        // Apply to element.
        el.style.position = "absolute"
        el.style.width = videoWidth + 'px'
        el.style.height = videoHeight + 'px'
        el.style.left = videoLeft + 'px'
        el.style.top = videoTop + 'px'

        // Save a copy.
        let elRect = el.getBoundingClientRect()
        setVideoAbsRect(elRect);
       
      }      

    }

    /**
     * Fits the video to fixed inset boundaries, and centers it over the screen. The video
     * will scale appropriately so that it is always using as much space as possible while
     * preserving aspect ratio.
     */
    function fitVideo_windowed() {

      // Inset boundaries. Controls sit outside of these boundaries.
      let paddingLeft = 80
      let paddingRight = 110
      let paddingTop = 70
      let paddingBottom = 100

      // Aspect ratio of the raw video frame.
      let videoRatio = 16 / 9
      
      // Aspect ratio of the windowed video container.
      let windowWidth = window.innerWidth - (paddingRight + paddingLeft)
      let windowHeight = window.innerHeight - (paddingBottom + paddingTop)
      let windowRatio = windowWidth / windowHeight

      // Calculate these based on the size of the window. These values will be applied as the
      // new boundaries of the windowed video.
      let videoWidth = 0
      let videoHeight = 0
      let videoTop = 0
      let videoLeft = 0

      if (windowRatio > videoRatio) {   

        // Video will clip if we use full width, so constrain the video size to full height and
        // calculate the width accordingly.
        videoHeight = window.innerHeight - (paddingBottom + paddingTop)
        videoWidth = (videoHeight * 16) / 9

      } 
      else {

        // Video will clip if we use full height, so constrain to the width of the full width and
        // calculate the height accordingly.
        videoWidth = window.innerWidth - (paddingRight + paddingLeft)
        videoHeight = (videoWidth * 9) / 16

      }

      // Center over bounds
      videoLeft = paddingLeft + (windowWidth - videoWidth) / 2
      videoTop = paddingTop + (windowHeight - videoHeight) / 2

      // Apply to the windowed video container.
      let el = document.getElementById('windowed-video-container');
      if (el) {
      
        let newRect = {
          x: videoLeft,
          y: videoTop,
          width: videoWidth,
          height: videoHeight
        }

        // Apply to element.
        el.style.position = "absolute"
        el.style.width = videoWidth + 'px'
        el.style.height = videoHeight + 'px'
        el.style.left = videoLeft + 'px'
        el.style.top = videoTop + 'px'

        // Save a copy.
        setVideoAbsRect(newRect);
       
      }      
      
    }

    /**
     * Fits the video div into the window as large as it can be while maintaining aspect ratio,
     * not clipping the contents of the video, and not colliding with border controls.
     */
    function fitVideo() {

      return (isFullscreen === true
        ? fitVideo_fullscreen()
        : fitVideo_windowed()
      )
    }
    
    window.addEventListener('resize', fitVideo)

    fitVideo();
  }, [isFullscreen]);

  // ...

  const titlesCollection = useQuery(
    useCallback(
      () => firebase.fetchCollection('titles'),
      []
    )
  );
  
  // ...
  // Determine background element to display.
  let backgroundElement;

  if (state.matches('default') || state.matches('live_annotating')) {
    if (isFullscreen) {
      backgroundElement = 
        <div id="video-container">
          {!state.context.videoSubscribed
            ? <div className={clsx(styles.text, styles.center)}>Waiting for video feed... <br></br> If this screen persists, your network may be blocking video transmission.</div>
            : null}
        </div>
    }
    else {
      backgroundElement = 
        <div id='windowed-video-container' className={styles.windowedVideoContainer}>
          <div id='video-container'>
          </div>
        </div>
    }
  } 
  // else if (state.matches('capturing')) {
  //   backgroundElement = (
  //     <div
  //       id="video-container"
  //       className={clsx(isFullscreen ? styles.fullscreen : styles.windowed, styles.hide)}
  //     >
  //       <div className={clsx(styles.text, styles.center)}>Capturing video frame...</div>
  //     </div>
  //   )
  // } else if (state.matches('captured')) {
  //   backgroundElement = (
  //     <Frame frame={state.context.frameGrab} />
  //   )
  // } else if (state.matches('annotating')) {
  //   backgroundElement = (
  //     <Annotation
  //       type={state.context.annotationType}
  //       frame={state.context.frameGrab}
  //       actions={{
  //         onDiscard() {
  //           send('CANCEL');
  //         },
  //         onAnnotation(annotationType, annotationData) {
  //           const {
  //             frameData
  //           } = state.context;

  //           send({
  //             type: 'ANNOTATION',
  //             data: {
  //               type: annotationType,

  //               ...annotationData,

  //               ...frameData
  //             }
  //           });
  //         }
  //       }}
  //       color={context.memberColors[user.uid]}
  //     />
  //   )
  // }  
  else {
    backgroundElement = null;
  }

  // ...
  
  return (
    <>
      <div id="video-background" className={styles.background}>
        {backgroundElement}
      </div>

      {(state.matches('annotating') || state.matches('live_annotating')) && isFullscreen
        ? null
        : (
          <div className={clsx(styles.overlay, styles.fullscreen)}>
            <Toolbar avatarColors={context.memberColors} />
            <Controls
              context={{
                mute,
                members: context.members,
                memberColors: context.memberColors,
                titlesCollection,
                isFullscreen,
                setFullscreen,
                videoAbsRect,
              }}
              actions={{
                ...actions,
                setMute
              }}
              styleProps={{
                root: {
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }
              }}              
            >
              {process.env.REACT_APP_DISABLE_ANNOTATIONS === 'false'
                ? getAnnotationElement(state, send)
                : null}
            </Controls>

          </div>
        )}
      {!state.matches("live_annotating")
        ? null
        : (
          <div className = {styles.overlay}>  
            <LiveAnnotation
              id="liveAnnotationControl"
              type={"HOTSPOT"}
              context={{
                videoAbsRect,
                isFullscreen
              }}
              style={{
                zIndex: 1000
              }}
              actions={{
                onDiscard() {
                  send('CANCEL');
                },
                onAnnotation(annotationType, annotationData) {
                  var annotationId= user.uid + annotationData.CreationTime
                  send({
                    type: 'LIVE_ANNOTATION',
                    data: {
                      type: annotationType,

                      ...annotationData,
                      
                      referenceFrame: null,

                      id: annotationId
                    }
                  });
                },
                onEndAnnotation(annotationData) {
                  var annotationId = user.uid + annotationData.CreationTime
                  send({
                    type: 'LIVE_ANNOTATION_END',
                    data: {
                      id: annotationId
                    }
                  })
                }
              }}
              color={context.memberColors[user.uid]}
            />
          </div>
        )}
    </>
  );
}