import ArrowImage from 'assets/annotation_arrow.svg';

import {
  normalizePoint,
  loadImage,
  offsetFromMouseEvent,
  offsetFromTouchEvent
} from './util';

// ...

let arrowImage;

async function getArrow() {
  if (!arrowImage) {
    arrowImage = await loadImage(ArrowImage);
  }

  return arrowImage;
}

export class Arrow {
  position;

  constructor(canvas, color) {
    this.canvas = canvas;

    this.color = color
      ? `
    rgb(${Math.round(color.r * 255)}, ${Math.round(color.g * 255)}, ${Math.round(color.b * 255)})
  `
      : '#EECB4E';

  }

  drawArrow = async ({ offsetX, offsetY }) => {
    const ctx = this.canvas.getContext('2d');
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    const arrow = await getArrow();

    let drawX = offsetX - (arrow.width / 2);
    let drawY = offsetY - arrow.height;

    let scale = 1;
    if (drawY < 0) {
      drawY = -(offsetY + arrow.height);
      scale = -1;
    }

    ctx.save();

    ctx.fillStyle = this.color;
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    // set composite mode
    ctx.globalCompositeOperation = "destination-in";

    ctx.scale(1, scale);
    ctx.drawImage(arrow, drawX, drawY, arrow.width, arrow.height);

    ctx.globalCompositeOperation = "source-over";

    ctx.restore();

    // ...

    const ex = offsetX;
    const ey = offsetY;
    const sx = offsetX;
    const sy = ey - (scale * arrow.height);

    this.arrowData = {
      sx, sy,
      ex, ey
    };
  }

  onMouseDown = ({ nativeEvent }) => {
    this.drawArrow(offsetFromMouseEvent(nativeEvent));
  }

  onTouchStart = event => {
    event.preventDefault();

    this.drawArrow(offsetFromTouchEvent(event.nativeEvent));
  }

  // ...

  getEventHandlers() {
    return {
      onMouseDown: this.onMouseDown,
      onTouchStart: this.onTouchStart
    };
  }

  getAnnotationData() {
    if (!this.arrowData) {
      return null;
    }

    const {
      sx, sy,
      ex, ey
    } = this.arrowData;

    return {
      arrowData: {
        Start: normalizePoint(sx, sy, this.canvas.width, this.canvas.height),
        End: normalizePoint(ex, ey, this.canvas.width, this.canvas.height)
      }
    };
  }
}