const titleFormatters = {
  withLastName(titleName, profile) {
    return `${titleName} ${profile.last}`;
  },
  withDefault(titleName, profile) {
    return `${titleName} ${profile.first}`;
  }
};

export function formatTitle(titles, profile) {
  if (profile.titleRef) {
    const titleEntry = titles.find(title => title.id === profile.titleRef);
    if (titleEntry) {
      return titleFormatters[titleEntry.formatter]
        ? titleFormatters[titleEntry.formatter](titleEntry.name, profile)
        : titleFormatters.withDefault(titleEntry.name, profile);
    }
  }

  return profile.first;
}